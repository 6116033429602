const ModuleConfig = {
  primaryKey: "_id",

  formDefaultValue: {
    name: "",
  },

  formFields: {
    firstName: {
      label: "First Name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    lastName: {
      label: "lastName",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    email: {
      label: "Email",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    gender: {
      label: "Gender",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Gender",
      isAdd: false,
      options: [
        {
          label: "Male",
          value: "male",
        },
        {
          label: "Female",
          value: "female",
        },
      ],
    },
    interested_in: {
      label: "Interested In",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select interest",
      isAdd: false,
      options: [
        {
          label: "Women",
          value: "Women",
        },
        {
          label: "Men",
          value: "Men",
        },
        {
          label: "Both",
          value: "Both",
        },
      ],
    },
    looking_for: {
      label: "Looking For",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    location: {
      label: "Location",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    bio: {
      label: "Bio",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    age: {
      label: "Age",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    isConfirmed: {
      label: "Is Confirmed",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select",
      isAdd: false,
      options: [
        {
          label: "True",
          value: true,
        },
        {
          label: "False",
          value: false,
        },
      ],
    },
    isPremium: {
      label: "Is Premium",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select",
      isAdd: false,
      options: [
        {
          label: "True",
          value: true,
        },
        {
          label: "False",
          value: false,
        },
      ],
    },

    status: {
      label: "Approval Status",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      isAdd: false,
      options: [
        {
          label: "Approved",
          value: true,
        },
        {
          label: "Disapproved",
          value: false,
        },
      ],
    },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
