import { Route } from "react-router-dom";
import StoreCreate from "./pages/StoreCreate";
import StoreList from "./pages/StoreList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "store"; 
const StoreRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<StoreList />} key={`${MODULE_ROUTE}_index`} />,
    // <Route path={`${MODULE_ROUTE}/create`} element={<StoreCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<StoreCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default StoreRoute;