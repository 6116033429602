const ModuleConfig = {
    "primaryKey": "id",
    "formDefaultValue" : {
        "name" : "",
        "description" : "",
        "title" : ""
    },
    "formFields": {
        "title": {
            "label": "Title",
            "isRequired": false,
            "colWidth": "col-md-12",
            "inputType": "text",
            "type" :"text"
        },
        "description": {
            "label": "Description",
            "isRequired": false,
            "colWidth": "col-md-12",
            "inputType": "ckeditor",
            "type" :"textarea"
        },
        "status": {
            "label": "Status",
            "isRequired": false,
            "colWidth": "col-md-6",
            "inputType": "select",
            "type" :"select",
            "placeholder":"Select Status",
            "options" : [{
                "label" : "Active",
                "value" : "Active"
            }, {
                "label" : "Inactive",
                "value" : "Inactive"
            }]
        }
    },
    "listColumnOrder": [
        "title",
        "status"    
    ]
}

export default ModuleConfig