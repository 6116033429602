import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { StoreDetailInterface } from "./models/StoreDetailInterface";
import {
  createStore,
  deleteStore,
  getStoreDetail,
  getStoreList,
  updateStore,
} from "./StoreAPI";

export interface StoreStateInterface {
  stores: StoreDetailInterface[];
  favourites: StoreDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: StoreDetailInterface;
}

const initialState: StoreStateInterface = {
  stores: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    image: "",
    status: "",
  },
};

export const getStoreListAsync = createAsyncThunk(
  "store/list",
  async (data: any) => {
    const response = await getStoreList(data);
    return response.data;
  }
);
export const getStoreDetailAsync = createAsyncThunk(
  "store/detail",
  async (index: string) => {
    const response = await getStoreDetail(index);
    return response.data.data;
  }
);

export const createStoreAsync = createAsyncThunk(
  "store/create",
  async (data: StoreDetailInterface) => {
    const response = await createStore(data);
    return response.data;
  }
);

export const deleteStoreAsync = createAsyncThunk(
  "store/delete",
  async (index: string) => {
    const response = await deleteStore(index);
    return response.data;
  }
);

export const updateStoreAsync = createAsyncThunk(
  "store/update",
  async (data: { index: string; data: StoreDetailInterface }) => {
    const response = await updateStore(data.index, data.data);
    return response.data;
  }
);

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getStoreListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStoreListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.stores = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getStoreListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getStoreDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStoreDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getStoreDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createStoreAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createStoreAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createStoreAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateStoreAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateStoreAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateStoreAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteStoreAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteStoreAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteStoreAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = storeSlice.actions;
export const StoreState = (state: RootState) => state.store;
export default storeSlice.reducer;
