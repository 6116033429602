import { useEffect, useState } from "react";
import { Spinner, Alert } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import {
  appState,
  getDashboardAsync,
  getProfileAsync,
} from "../stores/appSlice";
import { useAppDispatch, useAppSelector } from "../stores/hooks";

const Dashboard = () => {
  const { status, dashboard } = useAppSelector(appState);
  const dispatchApp = useAppDispatch();

  console.log(dashboard);

  useEffect(() => {
    dispatchApp(getDashboardAsync());
  }, []);

  const formatter = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  return (
    <div className="pcoded-wrapper">
      <ToastContainer />
      <div className="pcoded-content">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              {status === "loading" ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : status === "failed" ? (
                <Alert key={"danger"} variant={"danger"}>
                  Somthing went wrong please try again
                </Alert>
              ) : (
                <>
                  {/* [ Main Content ] start  */}
                  <div className="row">
                    {/*[ daily sales section ] start */}
                    <div className="col-md-6 col-xl-4">
                      <div className="card daily-sales">
                        <div className="card-block">
                          <h6 className="mb-4">Users</h6>
                          <div className="row d-flex align-items-center">
                            <div className="col-9">
                              <h3 className="f-w-600 text-c-green d-flex align-items-center m-b-0">
                                <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                {dashboard?.usersCount}
                              </h3>
                            </div>

                            {/* <div className="col-3 text-right">
                          <p className="m-b-0">67%</p>
                        </div> */}
                          </div>
                          <div
                            className="progress m-t-30"
                            style={{ height: "7px" }}
                          >
                            <div
                              className="progress-bar progress-c-theme"
                              role="progressbar"
                              style={{ width: "100%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*[ daily sales section ] end */}

                    {/*[ Monthly  sales section ] starts */}
                    <div className="col-md-6 col-xl-4">
                      <div className="card Monthly-sales">
                        <div className="card-block">
                          <h6 className="mb-4">Premium Users</h6>
                          <div className="row d-flex align-items-center">
                            <div className="col-9">
                              <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                {dashboard?.premiumUsersCount}
                              </h3>
                            </div>
                            <div className="col-3 text-right">
                              {/* <p className="m-b-0">36%</p> */}
                            </div>
                          </div>
                          <div
                            className="progress m-t-30"
                            style={{ height: "7px" }}
                          >
                            <div
                              className="progress-bar progress-c-theme"
                              role="progressbar"
                              style={{ width: "100%" }}
                              aria-valuenow={35}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*[ Monthly  sales section ] end */}

                    {/*[ year  sales section ] starts */}
                    <div className="col-md-12 col-xl-4">
                      <div className="card yearly-sales">
                        <div className="card-block">
                          <h6 className="mb-4">Videos</h6>
                          <div className="row d-flex align-items-center">
                            <div className="col-9">
                              <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                {dashboard.videosCount}
                              </h3>
                            </div>
                            <div className="col-3 text-right">
                              {/* <p className="m-b-0">80%</p> */}
                            </div>
                          </div>
                          <div
                            className="progress m-t-30"
                            style={{ height: "7px" }}
                          >
                            <div
                              className="progress-bar progress-c-theme"
                              role="progressbar"
                              style={{ width: "1000%" }}
                              aria-valuenow={70}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*[ year  sales section ] end */}

                  
                  </div>
                  {/* [ Main Content ] end  */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
