import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getStoreImageListAsync, StoreImageState } from "../StoreImageSlice";
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";
import { StoreImageDetailInterface } from "../models/StoreImageDetailInterface";
import ModuleConfig from "./../config/ModuleConfig";
import CustomPagination from "../../../utils/CustomPagination";
import { deleteStoreImage } from "../StoreImageAPI";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Formik } from "formik";
import AxiosInstance from "../../../utils/AxiosInstance";
import Config from "../../../utils/config";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

/**
 * StoreImageing list page
 */
const StoreImageList = () => {
  const { storeimages, status, totalRecord } = useAppSelector(StoreImageState);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const inputFileRef55 = useRef(null);

  const [deleteItem, setDeleteItem] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<any>("");

  useEffect(() => {
    if (selectedFile) {
      setPreview(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  useEffect(() => {
    getPageData();
  }, [dispatch, page]);

  const getPageData = () => {
    const filter = {
      page: page,
    };
    dispatch(getStoreImageListAsync(filter));
  };

  const removeStoreImage = async () => {
    const res = await deleteStoreImage(deleteItem);
    setDeleteItem("");
    getPageData();
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const columns = [
    {
      dataField: "file_path",
      text: "Thumbnail",
      classes: "col-1",
      headerAlign: "center",
      formatter: (cellContent: any, row: any) => (
        <div className="">
          <img
            className="thumbnail"
            src={Config.filePath + "store/" + row.file_path}
            alt={row.file_path}
          />
        </div>
      ),
    },
    {
      dataField: "filename",
      text: "File name",
    },
    {
      dataField: "subcategory",
      text: "Sub category",
    },

    {
      dataField: "size",
      text: "Size",
    },

    {
      dataField: "actions",
      text: "Actions",
      classes: "col-2",
      headerAlign: "center",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex justify-content-around">
          <i
            className="feather icon-trash-2 text-danger fw-bold"
            onClick={() => {
              setDeleteItem(row._id);
            }}
          ></i>
          {/* <i
            className="feather icon-edit text-success fw-bold"
            onClick={() => {
              navigate(`/storeimage/update/${row._id}`);
            }}
          ></i> */}
          <i
            onClick={() => {
              setImageUrl(row.file_path);
            }}
            className="feather icon-eye text-secondary fw-bold"
          ></i>
        </div>
      ),
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  };

  // Image Modal
  const onImageFormSubmit = async (values: any) => {
    const formData = new FormData();
    formData.append("store", selectedFile);
    formData.append("filename", values.fileName);
    formData.append("size", values.fileSize);
    formData.append("category", values.fileCategory);
    formData.append("approve", "false");

    const res = await AxiosInstance.post("storeimages/upload", formData);
    if (res.data.status) {
      setOpenImageModal(false);
      toast.success("Image Uploaded!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setPreview("");
      getPageData();
    }
  };

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Store Image</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Store Images</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Store Images</h5>
                    </Col>
                    <Col md={2}>
                      {/* <Link className="btn btn-primary" to="/storeimage/create">
                        Add StoreImage
                      </Link> */}
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={storeimages}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <div>
                              <SearchBar {...props.searchProps} />
                              <ClearSearchButton
                                {...props.searchProps}
                                className="btn-primary fw-bold ms-2"
                              />

                              <Button
                                className="btn-warning fw-bold ms-2"
                                onClick={() => {
                                  setOpenImageModal(true);
                                }}
                              >
                                Upload Image
                              </Button>
                            </div>
                            <BootstrapTable
                              filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props.baseProps}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>

                      {/* <CustomPagination
                        curretnPage={page}
                        total={totalRecord}
                        limit={10}
                        setPage={setPage}
                      /> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal backdrop={false} show={true} onHide={() => setDeleteItem("")}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete Store Image?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteItem("")}>
              Close
            </Button>
            <Button
              className="btn-danger"
              variant="primary"
              onClick={removeStoreImage}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : openImageModal ? (
        <Modal
          backdrop={false}
          size={"lg"}
          show={true}
          onHide={() => {
            setOpenImageModal(false);
            setPreview("");
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Modal.Body>
                  <div>
                    <Formik
                      initialValues={{
                        file: "",
                        fileName: "",
                        fileSize: "",
                        fileCategory: "",
                      }}
                      validate={(values: {
                        file: string;
                        fileName: string;
                        fileSize: string;
                        fileCategory: string;
                      }) => {
                        const errors: {
                          file?: string;
                          fileName?: string;
                          fileSize?: string;
                          fileCategory?: string;
                        } = {};

                        if (!values.file) {
                          errors.file = "Required";
                        }
                        if (!values.fileName) {
                          errors.fileName = "Required";
                        }
                        if (!values.fileSize) {
                          errors.fileSize = "Required";
                        }
                        if (!values.fileCategory) {
                          errors.fileCategory = "Required";
                        }
                        return errors;
                      }}
                      onSubmit={onImageFormSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <form id="image-upload-form" onSubmit={handleSubmit}>
                          <div className="form-group mb-3">
                            <label htmlFor="file">Select File</label>
                            <input
                              ref={inputFileRef55}
                              className={
                                errors.file && touched.file && errors.file
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              name="file"
                              onChange={(e) => {
                                handleChange(e);
                                setSelectedFile(e.target.files[0]);
                              }}
                              onBlur={handleBlur}
                              value={values.file}
                              placeholder="Enter file"
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label htmlFor="fileName">File Name</label>
                            <input
                              className={
                                errors.fileName &&
                                touched.fileName &&
                                errors.fileName
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                              type="text"
                              name="fileName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.fileName}
                              placeholder="Enter file name"
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label htmlFor="fileName">File Size</label>
                            <select
                              name="fileSize"
                              value={values.fileSize}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.fileSize &&
                                touched.fileSize &&
                                errors.fileSize
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                            >
                              <option value="" label="Select file size">
                                {" "}
                                Select file size{" "}
                              </option>
                              <option value="small" label="Small">
                                {" "}
                                Small{" "}
                              </option>
                              <option value="large" label="large">
                                {" "}
                                Large{" "}
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-3">
                            <label htmlFor="fileCategory">File Category</label>
                            <select
                              name="fileCategory"
                              value={values.fileCategory}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.fileCategory &&
                                touched.fileCategory &&
                                errors.fileCategory
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                            >
                              <option value="" label="Select file category">
                                {" "}
                                Select file category{" "}
                              </option>
                              <option value="citylife" label="Citylife">
                                {" "}
                                Citylife{" "}
                              </option>
                              <option value="flags" label="Flags">
                                {" "}
                                Flags{" "}
                              </option>
                              <option value="freeitems" label="Free Items">
                                {" "}
                                Free items{" "}
                              </option>
                              <option value="nature" label="Nature">
                                {" "}
                                Nature{" "}
                              </option>
                            </select>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </Modal.Body>
              </Col>
              <Col md={6}>
                <div className="upload-image-preview">
                  <img
                    className="image-preview"
                    src={
                      preview
                        ? preview
                        : require("./../../../assets/images/app-logo.jpg")
                    }
                    alt="preview"
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setOpenImageModal(false);
                setPreview("");
              }}
            >
              Close
            </Button>
            <Button
              className="btn-success"
              variant="primary"
              type="submit"
              form="image-upload-form"
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      ) : imageUrl ? (
        <Modal backdrop={false} show={true} onHide={() => setImageUrl("")}>
          <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="upload-image-preview">
              <img
                className="image-preview"
                src={`${Config.filePath}store/${imageUrl}`}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setImageUrl("")}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};
export default StoreImageList;
