import axios from "axios";
import config from "./config";
   
const AxiosInstance = axios.create({
  baseURL: config.baseURL,
  timeout: 35000,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer ",
    // "Content-Type": "multipart/form-data",
    "Content-Type": "application/json",
  },
});

AxiosInstance.interceptors.request.use(
  async (config: any) => {
    axios.defaults.timeout = 35000;
    config.baseURL = config.baseURL;
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = "Bearer ".concat(token);
    }
    return config;
  },
  (error) => Promise.reject(error)
);



export default AxiosInstance;
