import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  appState,
  changeMobileMenuState,
  getProfileAsync,
} from "../../stores/appSlice";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";

const Header = () => {
  const dispatchApp = useAppDispatch();
  const navigate = useNavigate();
  const {isMobileMenuOpen} = useAppSelector(appState);
  const token = localStorage.getItem("token");
  
  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      dispatchApp(getProfileAsync());
    }
  }, []);



  return (
    <header className="navbar pcoded-header navbar-expand-lg navbar-light">
      <div className="m-header">
        <a
          className={isMobileMenuOpen ? "mobile-menu on" : "mobile-menu"}
          onClick={() => dispatchApp(changeMobileMenuState())}
          id="mobile-collapse1"
        >
          <span></span>
        </a>
        <a href="home" className="b-brand">
          <div className="b-bg">
            <img className="app-logo" src="/images/app-logo.png" />
          </div>
          <span className="b-title">DATING APP</span>
        </a>
      </div>
    </header>
  );
};

export default Header;
