import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { StoreImageDetailInterface } from "./models/StoreImageDetailInterface";
import {
  createStoreImage,
  deleteStoreImage,
  getStoreImageDetail,
  getStoreImageList,
  updateStoreImage,
} from "./StoreImageAPI";

export interface StoreImageStateInterface {
  storeimages: StoreImageDetailInterface[];
  favourites: StoreImageDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: StoreImageDetailInterface;
}

const initialState: StoreImageStateInterface = {
  storeimages: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    filename: "",
  },
};

export const getStoreImageListAsync = createAsyncThunk(
  "storeimage/list",
  async (data: any) => {
    const response = await getStoreImageList(data);
    return response.data;
  }
);
export const getStoreImageDetailAsync = createAsyncThunk(
  "storeimage/detail",
  async (index: string) => {
    const response = await getStoreImageDetail(index);
    return response.data.data;
  }
);

export const createStoreImageAsync = createAsyncThunk(
  "storeimage/create",
  async (data: StoreImageDetailInterface) => {
    const response = await createStoreImage(data);
    return response.data;
  }
);

export const deleteStoreImageAsync = createAsyncThunk(
  "storeimage/delete",
  async (index: string) => {
    const response = await deleteStoreImage(index);
    return response.data;
  }
);

export const updateStoreImageAsync = createAsyncThunk(
  "storeimage/update",
  async (data: { index: string; data: StoreImageDetailInterface }) => {
    const response = await updateStoreImage(data.index, data.data);
    return response.data;
  }
);

export const storeimageSlice = createSlice({
  name: "storeimage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getStoreImageListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStoreImageListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.storeimages = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getStoreImageListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getStoreImageDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStoreImageDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getStoreImageDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createStoreImageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createStoreImageAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createStoreImageAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateStoreImageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateStoreImageAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateStoreImageAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteStoreImageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteStoreImageAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteStoreImageAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = storeimageSlice.actions;
export const StoreImageState = (state: RootState) => state.storeimage;
export default storeimageSlice.reducer;
