import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { ImageDetailInterface } from "./models/ImageDetailInterface";
import {
  createImage,
  deleteImage,
  getImageDetail,
  getImageList,
  updateImage,
} from "./ImageAPI";

export interface ImageStateInterface {
  images: ImageDetailInterface[];
  favourites: ImageDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: ImageDetailInterface;
}

const initialState: ImageStateInterface = {
  images: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    image: "",
    status: "",
    file_path : ""
  },
};

export const getImageListAsync = createAsyncThunk(
  "image/list",
  async (data: any) => {
    const response = await getImageList(data);
    return response.data;
  }
);
export const getImageDetailAsync = createAsyncThunk(
  "image/detail",
  async (index: string) => {
    const response = await getImageDetail(index);
    return response.data.data;
  }
);

export const createImageAsync = createAsyncThunk(
  "image/create",
  async (data: ImageDetailInterface) => {
    const response = await createImage(data);
    return response.data;
  }
);

export const deleteImageAsync = createAsyncThunk(
  "image/delete",
  async (index: string) => {
    const response = await deleteImage(index);
    return response.data;
  }
);

export const updateImageAsync = createAsyncThunk(
  "image/update",
  async (data: { index: string; data: ImageDetailInterface }) => {
    const response = await updateImage(data.index, data.data);
    return response.data;
  }
);

export const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getImageListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getImageListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.images = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getImageListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getImageDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getImageDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getImageDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createImageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createImageAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createImageAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateImageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateImageAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateImageAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteImageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteImageAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteImageAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = imageSlice.actions;
export const ImageState = (state: RootState) => state.image;
export default imageSlice.reducer;
