import { Route } from "react-router-dom";
import VideoCreate from "./pages/VideoCreate";
import VideoList from "./pages/VideoList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "video"; 
const VideoRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<VideoList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<VideoCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<VideoCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default VideoRoute;