import { Route } from "react-router-dom";
import ImageCreate from "./pages/ImageCreate";
import ImageList from "./pages/ImageList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "image"; 
const ImageRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<ImageList />} key={`${MODULE_ROUTE}_index`} />,
    // <Route path={`${MODULE_ROUTE}/create`} element={<ImageCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<ImageCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default ImageRoute;