import { Route } from "react-router-dom";
import ContentCreate from "./pages/ContentCreate";
import ContentList from "./pages/ContentList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "content"; 
const ContentRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<ContentList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<ContentCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<ContentCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default ContentRoute;