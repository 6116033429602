import { getPropByString } from "../../../utils/CommonFunction"

const TextInput = (props: any) => {

    return (
        <input 
            type={props.type}
            readOnly={props?.readOnly}
            name={props.name}
            id={props.name}
            value={getPropByString(props.values, props.name)}
            className="form-control"
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            placeholder={props.fieldDetails.label} />
    )
}

export default TextInput;