import { Route } from "react-router-dom";
import StoreImageCreate from "./pages/StoreImageCreate";
import StoreImageList from "./pages/StoreImageList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "storeimage"; 
const StoreImageRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<StoreImageList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<StoreImageCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<StoreImageCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default StoreImageRoute;