const ModuleConfig = {
  primaryKey: "_id",

  formDefaultValue: {
    name: "",
    interested_in: ""
  },

  formFields: {
    firstName: {
      label: "First Name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    lastName: {
      label: "Last Name",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    email: {
      label: "Email",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    gender: {
      label: "Gender",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Gender",
      isAdd: false,
      options: [
        {
          label: "Male",
          value: "male",
        },
        {
          label: "Female",
          value: "female",
        },
      ],
    },
    interested_in: {
      label: "Interested In",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select interest",
      isAdd: false,
      options: [
        {
          label: "Women",
          value: "women",
        },
        {
          label: "Men",
          value: "men",
        },
        {
          label: "Both",
          value: "both",
        },
      ],
    },
    looking_for: {
      label: "Looking For",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      placeholder: "Select Looking For",
      type: "select",
      options: [
        { label: 'Casual', value: 'Casual' },
        { label: 'Dating', value: 'dating' },
        { label: 'Serious', value: 'serious ' },
        { label: 'Marriage', value: 'marriage ' }
      ],
    },
    location: {
      label: "Location",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    bio: {
      label: "Bio",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "textarea",
      type: "text",
    },
    age: {
      label: "Age",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },


    isPremium: {
      label: "Is Premium",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select",
      isAdd: false,
      options: [
        {
          label: "True",
          value: true,
        },
        {
          label: "False",
          value: false,
        },
      ],
    },

    status: {
      label: "Approval Status",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      isAdd: false,
      options: [
        {
          label: "Approved",
          value: true,
        },
        {
          label: "Disapproved",
          value: false,
        },
      ],
    },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
