import { Route } from "react-router-dom";
import ReportCreate from "./pages/ReportCreate";
import ReportList from "./pages/ReportList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "report"; 
const ReportRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<ReportList />} key={`${MODULE_ROUTE}_index`} />,
    // <Route path={`${MODULE_ROUTE}/create`} element={<ReportCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<ReportCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default ReportRoute;