import { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { appState } from "../../stores/appSlice";
import { useAppSelector } from "../../stores/hooks";
import AxiosInstance from "../../utils/AxiosInstance";
import MenuItems from "./menu.json";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobileMenuOpen, profile } = useAppSelector(appState);
  const [sideBarCollapsed, setSideBarCollapsed] = useState(false);

  const NO_SIDEBAR_MENU = ["/signin", "/signin/", "/signup", "/signup/"];

  const signout = async () => {
    const res = await AxiosInstance.get("signout");
    if (res?.status === 200) {
      localStorage.clear();
      navigate("/signin");
      window.location.reload();
    }
  };

  return NO_SIDEBAR_MENU.indexOf(location.pathname) === -1 ? (
    <Navbar
      className={
        isMobileMenuOpen
          ? "pcoded-navbar mob-open"
          : sideBarCollapsed
          ? "pcoded-navbar navbar-collapsed"
          : "pcoded-navbar"
      }
    >
      <div className="navbar-wrapper">
        <div className="navbar-brand header-logo">
          <Navbar.Brand as={Link} to="/" className="b-brand">
            <div className="b-bg">
              {/* <img className="app-logo" src="/images/app-logo.png" /> */}
              <i className="feather icon-trending-up"></i>
            </div>
            <span className="b-title">DATING APP</span>
          </Navbar.Brand>

          {/* <a className={sideBarCollapsed ? "mobile-menu on" : "mobile-menu"} id="mobile-collapse" onClick={() => setSideBarCollapsed(!sideBarCollapsed)}><span></span></a> */}
        </div>
        <div className="navbar-content scroll-div">
          <ul className="nav pcoded-inner-navbar">
            {MenuItems.map((menuItem, index) => {
              const isActive = location.pathname === menuItem.link;
              return  (
                <li
                  key={`menuItem_${index}`}
                  className={`${isActive ? "nav-item active" : "nav-item"} 
                  ${menuItem.visible ? "" : "d-none"} ${location.pathname === "/" && index === 0 ? "active" : ""} `}
                >
                  <Link to={menuItem.link} className="nav-link ">
                    <span className="pcoded-micon">
                      <i className={`feather ${menuItem.icon}`}></i>
                    </span>
                    <span className="pcoded-mtext">{menuItem.title}</span>
                  </Link>
                </li>
              );
            })}
            <hr />
            <li key={"signoutBtn"} className="nav-item">
              <div className="p-3 d-flex justify-content-between align-items-center nav-link bg-danger text-white">
                <div className="d-flex">
                  <span>
                    <img
                      className="rounded-circle "
                      src="images/user/avatar-2.jpg"
                      style={{ width: "40px" }}
                    />
                  </span>
                  <span className="ps-2 pcoded-mtext">
                    <div className="fw-bold">{profile?.firstName +" "+ profile?.lastName}</div>
                    <div className="fw-lighter">{profile?.isAdmin ? "Admin" : ""}</div>
                  </span>
                </div>
                <span
                  onClick={() => {
                    signout();
                  }}
                  className="pcoded-micon"
                >
                  <i className="feather icon-log-out"></i>
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Navbar>
  ) : null;
};

export default SideBar;
