import { useEffect, useState } from "react"
import { Pagination, Row, Col } from "react-bootstrap"

const CustomPagination = (props : { total : number, limit : number, curretnPage : number, setPage : Function, setLimit? : Function }) => {
  const [pages, setPages] = useState<any[]>([1])
  
  useEffect(() => {
    if (props.total) {
      var min = props.curretnPage - 2
      var max = Math.ceil(props.total / props.limit)
      if (min <= 0) {
        min = 1
      }
      if (props.curretnPage < max - 2) {
        max = props.curretnPage + 2
      }
      var pg : any[] = []
      for (var i = min; i <= max; i++) {
        pg.push(i)
      }
      setPages(pg)
    }
  }, [props.total, props.limit, props.curretnPage])

  const updatePage = (page : number) => {
    props.setPage(page)
  }

  return (
    <Row>
      <Col></Col>
      <Col>
        <Pagination className="justify-content-end">
          {props.curretnPage !== 1 ? (
            <>
              <Pagination.First onClick={() => updatePage(1)} />
              <Pagination.Prev onClick={() => updatePage(props.curretnPage - 1)} />
            </>
          ) : null}

          {pages.map((page, indes) => {
            return (
              <Pagination.Item
                active={page === props.curretnPage}
                key={"apge" + indes}
                onClick={() => updatePage(page)}
              >
                {page}
              </Pagination.Item>
            )
          })}
          {props.curretnPage !== Math.ceil(props.total / props.limit) ? (
            <>
              <Pagination.Next onClick={() => updatePage(props.curretnPage + 1)} />
              <Pagination.Last
                onClick={() => updatePage(Math.ceil(props.total / props.limit))}
              />
            </>
          ) : null}
        </Pagination>
      </Col>
    </Row>
  )
}

export default CustomPagination
