import { Button, Container, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getStoreDetailAsync, StoreState } from "../StoreSlice";
import { Formik } from "formik";
import { StoreDetailInterface } from "../models/StoreDetailInterface";
import ModuleConfig from "../config/ModuleConfig";
import FormField from "../../common/FormField";
import { useEffect } from "react";
import { createStore, updateStore } from "../StoreAPI";

/**
 * Storeing Favourites list page
 */
const StoreCreate = () => {
  const dispatch = useAppDispatch();
  const { status, detail } = useAppSelector(StoreState);
  const params = useParams();
  const navigator = useNavigate();

  useEffect(() => {
    if (params.id) {
      dispatch(getStoreDetailAsync(params.id)); 
    }
  }, [params, dispatch]);

  const handleSubmit = async (values: StoreDetailInterface, formMethod: any) => {
    if (params.id) {
      const res: any = await updateStore(params.id, values);
      if (res.errors && res.message) {
        const errors: any = {};
        const errorKeys = Object.keys(res.errors);
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0];
        }
        formMethod.setErrors(errors);
      } else {
        navigator("/store");
      }
    } else {
      const res: any = await createStore(values);
      if (res.errors && res.message) {
        const errors: any = {};
        const errorKeys = Object.keys(res.errors);
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0];
        }
        formMethod.setErrors(errors);
      } else {
        navigator("/store");
      }
    }
  };

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Store</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/store">Stores</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.id ? "Edit " : "Add "}Store</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>{params.id ? "Edit " : "Add "}Store</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    initialValues={
                      detail[ModuleConfig.primaryKey] == params.id
                        ? detail
                        : ModuleConfig.formDefaultValue
                    }
                    validate={(values) => {
                      const errors: any = {};
                      const fields = Object.keys(ModuleConfig.formFields);
                      for (let index = 0; index < fields.length; index++) {
                        if (
                          !values[fields[index]] &&
                          ModuleConfig.formFields[fields[index]].isRequired
                        ) {
                          errors[fields[index]] = "Required";
                        }
                      }

                      return errors;
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {Object.keys(ModuleConfig.formFields).map(
                            (field: string, index: number) => {
                              const fieldDetails =
                                ModuleConfig.formFields[field];
                              return (fieldDetails.isAdd == false &&
                                params.id) ||
                                fieldDetails.isAdd == undefined ? (
                                <div
                                  key={`field_${index}`}
                                  className={fieldDetails.colWidth}
                                >
                                  <FormField
                                    name={field}
                                    fieldDetails={fieldDetails}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              ) : null;
                            }
                          )}
                        </div>
                        <hr />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={status === "loading"}
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StoreCreate;
