import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { PremiumUserDetailInterface } from "./models/PremiumUserDetailInterface";
import {
  createPremiumUser,
  deletePremiumUser,
  getPremiumUserDetail,
  getPremiumUserList,
  updatePremiumUser,
} from "./PremiumUserAPI";

export interface PremiumUserStateInterface {
  premiumusers: PremiumUserDetailInterface[];
  favourites: PremiumUserDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: PremiumUserDetailInterface;
}

const initialState: PremiumUserStateInterface = {
  premiumusers: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    image: "",
    status: "",
  },
};

export const getPremiumUserListAsync = createAsyncThunk(
  "premiumuser/list",
  async (data: any) => {
    const response = await getPremiumUserList(data);
    return response.data;
  }
);
export const getPremiumUserDetailAsync = createAsyncThunk(
  "premiumuser/detail",
  async (index: string) => {
    const response = await getPremiumUserDetail(index);
    return response.data.data;
  }
);

export const createPremiumUserAsync = createAsyncThunk(
  "premiumuser/create",
  async (data: PremiumUserDetailInterface) => {
    const response = await createPremiumUser(data);
    return response.data;
  }
);

export const deletePremiumUserAsync = createAsyncThunk(
  "premiumuser/delete",
  async (index: string) => {
    const response = await deletePremiumUser(index);
    return response.data;
  }
);

export const updatePremiumUserAsync = createAsyncThunk(
  "premiumuser/update",
  async (data: { index: string; data: PremiumUserDetailInterface }) => {
    const response = await updatePremiumUser(data.index, data.data);
    return response.data;
  }
);

export const premiumuserSlice = createSlice({
  name: "premiumuser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getPremiumUserListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPremiumUserListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.premiumusers = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getPremiumUserListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getPremiumUserDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPremiumUserDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getPremiumUserDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createPremiumUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPremiumUserAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createPremiumUserAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updatePremiumUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePremiumUserAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updatePremiumUserAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deletePremiumUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePremiumUserAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deletePremiumUserAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = premiumuserSlice.actions;
export const PremiumUserState = (state: RootState) => state.premiumuser;
export default premiumuserSlice.reducer;
