import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const MyDropzone = (props: any) => {
  const [files, setFiles] = useState<any[]>([]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    // maxFiles: 20,
    multiple: false,
    
    onDrop: (acceptedFiles : any) => {
      var acceptedFilesWithPreview = acceptedFiles.map((file : any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
      setFiles([...files, ...acceptedFilesWithPreview])
      props.setFieldValue(props.name, ...files);

    },
  })

  

  return (
    <div>
      <div {...getRootProps({ className: "dropzone " })}>
        <input {...getInputProps()} />
        <h2>{"📁"}</h2>
        <p>Drag'n'drop images, or click to select files</p>
      </div>
    </div>
  );
};

export default MyDropzone;
