const ModuleConfig = {
  primaryKey: "_id",

  formDefaultValue: {
    name: "",
  },

  formFields: {
    // owner: {
    //   label: "owner",
    //   isRequired: true,
    //   colWidth: "col-md-6",
    //   inputType: "text",
    //   type: "text",
    // },

    // category: {
    //   label: "Category",
    //   isRequired: false,
    //   colWidth: "col-md-6",
    //   inputType: "text",
    //   type: "text",
    // },
    // file_path: {
    //   label: "File Path",
    //   isRequired: false,
    //   colWidth: "col-md-6",
    //   inputType: "text",
    //   type: "text",
    // },
    // dataOfDeletion: {
    //   label: "Data Of deletion",
    //   isRequired: false,
    //   colWidth: "col-md-6",
    //   inputType: "text",
    //   type: "text",
    // },
    

    // file_type: {
    //   label: "file_type",
    //   isRequired: false,
    //   colWidth: "col-md-6",
    //   inputType: "select",
    //   type: "select",
    //   placeholder: "Select",
    //   isAdd: false,
    //   options: [
    //     {
    //       label: "Video",
    //       value: "video",
    //     },
    //     {
    //       label: "Image",
    //       value: "image",
    //     },
    //   ],
    // },

    // watermark: {
    //   label: "Watermark",
    //   isRequired: false,
    //   colWidth: "col-md-6",
    //   inputType: "select",
    //   type: "select",
    //   placeholder: "Select",
    //   isAdd: false,
    //   options: [
    //     {
    //       label: "True",
    //       value: true,
    //     },
    //     {
    //       label: "False",
    //       value: false,
    //     },
    //   ],
    // },
    // isProcessed: {
    //   label: "Is Processed",
    //   isRequired: false,
    //   colWidth: "col-md-6",
    //   inputType: "select",
    //   type: "select",
    //   placeholder: "Select",
    //   isAdd: false,
    //   options: [
    //     {
    //       label: "True",
    //       value: true,
    //     },
    //     {
    //       label: "False",
    //       value: false,
    //     },
    //   ],
    // },

    // isDeleted: {
    //   label: "Is Deleted",
    //   isRequired: false,
    //   colWidth: "col-md-6",
    //   inputType: "select",
    //   type: "select",
    //   placeholder: "Select",
    //   isAdd: false,
    //   options: [
    //     {
    //       label: "True",
    //       value: true,
    //     },
    //     {
    //       label: "False",
    //       value: false,
    //     },
    //   ],
    // },

    isApproved: {
      label: "Approval Status",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      isAdd: false,
      options: [
        {
          label: "Approved",
          value: true,
        },
        {
          label: "Disapproved",
          value: false,
        },
      ],
    },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
