const ModuleConfig = {
  primaryKey: "_id",

  formDefaultValue: {
    name: "",
  },

  formFields: {
    filename: {
      label: "File name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    owner: {
      label: "Owner",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    ownerEmail: {
      label: "Owner Email",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    reportedBy: {
      label: "Reported By",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    status: {
      label: "Approval Status",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      isAdd: false,
      options: [
        {
          label: "Approved",
          value: true,
        },
        {
          label: "Disapproved",
          value: false,
        },
      ],
    },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
