import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

import UserRoute from "../modules/user/UserRoute";
import PremiumUserRoute from "../modules/premiumuser/PremiumUserRoute";
import VideoRoute from "../modules/video/VideoRoute";
import ImageRoute from "../modules/image/ImageRoute";
import ContentRoute from "../modules/content/ContentRoute";
import StoreImageRoute from "../modules/storeimage/StoreImageRoute";
import ReportRoute from "../modules/report/ReportRoute";
import Signin from "../modules/Signin";
import Signup from "../modules/Signup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { appState } from "../stores/appSlice";
import Dashboard from "../modules/Dashboard";
import StoreRoute from "../modules/store/StoreRoute";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
{
  /* Import New Entry */
}

/**
 * This will return all routes of the application
 */
const AppRoute = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const { isUserLoggedIn } = useSelector(appState);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoggedIn) {
      setToken(localStorage.getItem("token"));
    }
  }, [isUserLoggedIn]);

  const PrivateRoute = ({ children }: any) => {
    if (!token) {
      return <Navigate to="/signin" />;
    }
    return children;
  };

  return (
    <>
      <Routes>
        <Route path="/signin" element={<Signin />} />
      </Routes>

      <div className="pcoded-main-container">
      <ToastContainer />
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              {/* <PrivateRoute> */}
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                {UserRoute}
                {ContentRoute}
                {PremiumUserRoute}
                {VideoRoute}
                {ImageRoute}
                {StoreImageRoute}
                {ReportRoute}
                {StoreRoute}
{/* Add New Entry */}
              </Routes>
              {/* </PrivateRoute> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppRoute;
