import { Route } from "react-router-dom";
import PremiumUserCreate from "./pages/PremiumUserCreate";
import PremiumUserList from "./pages/PremiumUserList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "premiumuser"; 
const PremiumUserRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<PremiumUserList />} key={`${MODULE_ROUTE}_index`} />,
    // <Route path={`${MODULE_ROUTE}/create`} element={<PremiumUserCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    // <Route path={`${MODULE_ROUTE}/update/:id`} element={<PremiumUserCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default PremiumUserRoute;