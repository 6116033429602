import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { VideoDetailInterface } from "./models/VideoDetailInterface";
import {
  createVideo,
  deleteVideo,
  getVideoDetail,
  getVideoList,
  updateVideo,
} from "./VideoAPI";

export interface VideoStateInterface {
  videos: VideoDetailInterface[];
  favourites: VideoDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: VideoDetailInterface;
}

const initialState: VideoStateInterface = {
  videos: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",

  detail: {
    id: "",
    name: "",
    image: "",
    file_path:"",
    status: "",
  },
};

export const getVideoListAsync = createAsyncThunk(
  "video/list",
  async (data: any) => {
    const response = await getVideoList(data);
    return response.data;
  }
);
export const getVideoDetailAsync = createAsyncThunk(
  "video/detail",
  async (index: string) => {
    const response = await getVideoDetail(index);
    return response.data.data;
  }
);

export const createVideoAsync = createAsyncThunk(
  "video/create",
  async (data: VideoDetailInterface) => {
    const response = await createVideo(data);
    return response.data;
  }
);

export const deleteVideoAsync = createAsyncThunk(
  "video/delete",
  async (index: string) => {
    const response = await deleteVideo(index);
    return response.data;
  }
);

export const updateVideoAsync = createAsyncThunk(
  "video/update",
  async (data: { index: string; data: VideoDetailInterface }) => {
    const response = await updateVideo(data.index, data.data);
    return response.data;
  }
);

export const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getVideoListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getVideoListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.videos = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getVideoListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getVideoDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getVideoDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getVideoDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createVideoAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createVideoAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createVideoAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateVideoAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateVideoAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateVideoAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteVideoAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteVideoAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteVideoAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = videoSlice.actions;
export const VideoState = (state: RootState) => state.video;
export default videoSlice.reducer;
