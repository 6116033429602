import { useEffect, useState } from 'react';
// import Select from 'react-select';
// import AsyncSelect from 'react-select/async';
import AxiosInstance from '../../../utils/AxiosInstance';

export interface OptionInterface {
    value: string;
    label: string;
}
const InputSelect = (props: any) => {
    const [options, setOptions] = useState<OptionInterface[]>([]);

    useEffect(() => {
        if (!props.fieldDetails.asynchLoad) {
            setOptions(props.fieldDetails.options)
        } else {
            promiseOptions()
        }
    }, []);

    const promiseOptions = () => {
        return AxiosInstance.get(`${props.fieldDetails.dataUrl}`).then(res => {
            const opts: OptionInterface[] = [];
            for (let index = 0; index < res.data.data.length; index++) {
                opts.push({
                    value: res.data.data[index][props.fieldDetails.keyValue],
                    label: res.data.data[index][props.fieldDetails.keylabel]
                });
            }
            return setOptions(opts)
        })

    }
    return (
        <select
            name={props.name}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            className='form-control'>
            <option value={""}>{props.fieldDetails.placeholder}</option>
            {options.map((option: any) => {
                return <option selected={ option.value === props.values[props.name] } value={option.value}>{option.label}</option>
            })}
        </select>
    )
}

export default InputSelect;