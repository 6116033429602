import AxiosInstance from "../../utils/AxiosInstance";
import { StoreImageDetailInterface } from "./models/StoreImageDetailInterface";

const API_ROUTE = "storeimages";
export const getStoreImageList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`);
};

export const getStoreImageDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/${index}`);
};

export const createStoreImage = async (data: StoreImageDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]]);
  }

  return await AxiosInstance.post(`/${API_ROUTE}`, formData);
};

export const deleteStoreImage = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/${index}`);
};
export const updateStoreImage = async (index: string, data: StoreImageDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.post(`/${API_ROUTE}/${index}`, data);
};
