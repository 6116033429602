const FileInput = (props: any) => {

    return (
        <input 
            type={"file"}
            id={props.name}
            className="form-control"
            onBlur={props.handleBlur}
            onChange={(event) => {
                if(event?.currentTarget?.files){
                    props.setFieldValue(props.name, event?.currentTarget?.files[0]);
                }else{
                    props.setFieldValue(props.name, null);
                }
                
              }}
            placeholder={props.fieldDetails.label} />
    )
}

export default FileInput;