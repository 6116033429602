import { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getContentListAsync, ContentState } from "../ContentSlice";
import { ContentDetailInterface } from "../models/ContentDetailInterface";
import ModuleConfig from "./../config/ModuleConfig";
import CustomPagination from "../../../utils/CustomPagination";
import { deleteContent } from "../ContentAPI";
/**
 * Contenting list page
 */
const ContentList = () => {
  const { contents, status, totalRecord } = useAppSelector(ContentState);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  const [deleteItem, setDeleteItem] = useState("");

  useEffect(() => {
    getPageData();
  }, [dispatch, page]);

  const getPageData = () => {
    const filter = {
      page: page,
    };
    dispatch(getContentListAsync(filter));
  };

  const removeContent = async () => {
    const res = await deleteContent(deleteItem);
    setDeleteItem("")
    getPageData();
  };

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Content</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Contents</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Contents</h5>
                    </Col>
                    <Col md={2}>
                      <Link className="btn btn-primary" to="/content/create">
                        Add Content
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              {ModuleConfig.listColumnOrder.map(
                                (column: string, index: number) => {
                                  return (
                                    <th key={`head_${index}`} style={{width: "45%"}} >
                                      {ModuleConfig.formFields[column].label}
                                    </th>
                                  );
                                }
                              )}
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {contents?.length >= 1 ? (
                              contents.map(
                                (
                                  content: ContentDetailInterface,
                                  index: number
                                ) => {
                                  return (
                                    <tr key={`table_row_${index}`}>
                                      <>
                                        {ModuleConfig.listColumnOrder.map(
                                          (
                                            column: string,
                                            colIndex: number
                                          ) => {
                                            return (
                                              <td
                                                key={`table_row_${index}_${colIndex}`}
                                              >
                                               {colIndex == 1 ? (
                                                      <span
                                                        className={
                                                          "label " +
                                                          (content[column] ==
                                                          "Active"
                                                            ? "label-success"
                                                            : "label-danger")
                                                        }
                                                      >
                                                        {content[column]}
                                                      </span>
                                                    ) : (
                                                      content[column]
                                                    )}
                                              </td>
                                            );
                                          }
                                        )}

                                        <td>
                                        <Link  className="btn btn-icon btn-success" 
                                           to={`/content/update/${
                                            content[ModuleConfig.primaryKey]
                                          }`}
                                          >
                                            <i className="feather icon-edit"></i>
                                          </Link>

                                          <a
                                            className="btn btn-icon btn-danger"
                                            onClick={() =>
                                              setDeleteItem(content?.id || "")
                                            }
                                          >
                                            <i className="feather icon-trash-2"></i>
                                          </a>

                                        </td>
                                      </>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td
                                  colSpan={
                                    ModuleConfig.listColumnOrder.length + 1
                                  }
                                >
                                  No Record.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <CustomPagination
                        curretnPage={page}
                        total={totalRecord}
                        limit={10}
                        setPage={setPage}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal backdrop={false} show={true} onHide={() => setDeleteItem("")}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this Content?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteItem("")}>
              Close
            </Button>
            <Button variant="danger" onClick={removeContent}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};
export default ContentList;
