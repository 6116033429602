const TextAreaInput = (props: any) => {
  return (
    <textarea
      name={props.name}
      id={props.name}
      className="form-control"
      onBlur={props.handleBlur}
      value={props.values[props.name]}
      onChange={props.handleChange}
      placeholder={props.fieldDetails.label}
    ></textarea>
  );
};

export default TextAreaInput;
