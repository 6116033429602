import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import userReducer from "../modules/user/UserSlice";
import contentReducer from "../modules/content/ContentSlice";
import premiumuserReducer from "../modules/premiumuser/PremiumUserSlice";
import videoReducer from "../modules/video/VideoSlice";
import imageReducer from "../modules/image/ImageSlice";
import storeimageReducer from "../modules/storeimage/StoreImageSlice";
import reportReducer from "../modules/report/ReportSlice";
import storeReducer from "../modules/store/StoreSlice";
/* import store */
export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    content: contentReducer,
    premiumuser: premiumuserReducer,
    video : videoReducer,
image : imageReducer,
storeimage : storeimageReducer,
report : reportReducer,
store : storeReducer,
// {addStore}
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
