import { Col, Row } from "react-bootstrap";
import "./style.scss";
import "../../assets/js/common.js";
const Footer = () => {
  return (
    <div className="footer bg-light p-5">
      <Row>
        <Col>All content &copy; dnd5eapi</Col>
      </Row>
    </div>
  );
};

export default Footer;
